body {
  background-color: #FAF0E6;
  font-family: Arial, sans-serif;
  color: #003366;
}

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f6f9fc;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-content {
  position: absolute;
  top: 20%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
  z-index: 1;
  color: #eedebd; /* Match the button background color */
  margin-bottom: 20px; /* Add some spacing between text and button */
  padding-top: 20px; /* Add some spacing between text and top of image */
}

@media (max-width: 767px) {
  .hero {
    background: linear-gradient(180deg, #425b5f, #41595c, #1c3236);
    background-size: 200% 200%;
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    text-align: center;
    z-index: 1;
    color: #eedebd; /* Match the button background color */
    margin-bottom: 20px; /* Add some spacing between text and button */
    padding-top: 20px; /* Add some spacing between text and top of image */
  }

  .hero-image {
    display: none;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  background-color: #f6f9fc;
}

.login-title {
  font-size: 2rem;
  font-weight: 700;
  color: #284c59;
  margin-bottom: 1rem;
}

.login-button {
  background: linear-gradient(135deg, #3a8fd1, #1e6fbf, #2f7dc6);
  color: #fff;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background-position 0.5s, color 0.5s;
}

.login-button:hover {
  background-position: right bottom;
}


