.new-reminder-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="date"]:focus,
select:focus {
  outline: none;
  border-color: #5c7cfa;
}

.text-danger {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.new-reminder-container button[type="submit"] {
  background: linear-gradient(135deg, #425b5e, #24393b, #3a5255);
  background-size: 200% 200%;
  color: #f8d79b;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: background-position 0.5s, color 0.5s;
}

.new-reminder-container button[type="submit"]:hover {
  background-position: right bottom;
  color: #fff5e8;
}
