.dashboard {
  padding: 40px 0;
  background-color: #F5F5F5;  /* Main Body Content */
  color: #595959; /* Form and Text Color */
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #87CEFA;  /* Header color */
  background-size: 200% 200%;
  border-radius: 4px;
  padding: 20px;
}

/* DASHBOARD HEADER TITLE */
.title {
  font-size: 24px;
  font-weight: 700;
  color: #595959; /* Darker shade of gray for text */
}

/* BUTTON TO ADD A NEW CONTACT & REMINDER */
.dashboard-header > .btn-add-contact {
  background: linear-gradient(135deg, #FFA500, #E59400);  /* Button Gradient */
  background-size: 200% 200%;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-position 0.5s, color 0.5s;
  animation: Gradient 6s ease infinite;
}

.dashboard-header > .btn-add-contact:hover {
  color: #f8d79b;
}

/* BUTTON TO RUN THE REMINDERS FUNCTION AND SEND REMINDER TEXTS MANUALLY */
.btn-reminders {
  background: linear-gradient(135deg, #007BFF, #0056b3);  /* Button Gradient */
  background-size: 200% 200%;
  border: none;
  color: #FFFFFF; /* White text */
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: background-position 0.5s, color 0.5s;
}

.btn-reminders:hover {
  background-position: right bottom;
  color: #fff;
  animation: Gradient 1s ease infinite;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.contacts-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-bottom: 1px solid #ccc;
}

.contact-column {
  flex: 1;
}

.contact-name,
.contact-occasion,
.contact-date {
  margin: 0;
}

/* EDIT CONTACT BUTTON */
.contact-column > .btn {
  background: linear-gradient(135deg, #3366FF, #2B55D6);  /* Button Gradient */
  background-size: 200% 200%;
  color: #fff5e8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: background-position 0.5s, color 0.5s;
}

.contact-column > .btn:hover {
  background-position: right bottom;
  color: #f8d79b;
}

/* DELETE CONTACT BUTTON */
.btn-delete-contact {
  background: linear-gradient(90deg, #CC3333, #B02B2B);  /* Button Gradient */
  background-size: 200% 200%;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-position 0.5s, color 0.5s;
}

.btn-delete-contact:hover {
  color: #fff;
  animation: Gradient 1s ease infinite;
}

/* Responsive styles */
@media (max-width: 960px) {
  .dashboard-header {
    flex-direction: column;
  }

  .dashboard-header > .btn-add-contact,
  .btn-reminders {
    width: 100%;
    margin-top: 10px;
  }

  .contact {
    flex-direction: column;
  }

  .contact-column > .btn,
  .btn-delete-contact {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .dashboard-header > .btn-add-contact,
  .btn-reminders {
    font-size: 18px;
    padding: 15px 25px;
  }

  .contact-column > .btn,
  .btn-delete-contact {
    font-size: 18px;
    padding: 15px 25px;
  }
}


/* Responsive styles */
@media (max-width: 960px) {
  .dashboard-header {
    flex-direction: column;
  }

  .dashboard-header > .btn-add-contact,
  .btn-reminders {
    width: 100%;
    margin-top: 10px;
  }

  .contact {
    flex-direction: column;
  }

  .contact-column > .btn,
  .btn-delete-contact {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .dashboard-header > .btn-add-contact,
  .btn-reminders {
    font-size: 18px;
    padding: 15px 25px;
  }

  .contact-column > .btn,
  .btn-delete-contact {
    font-size: 18px;
    padding: 15px 25px;
  }
}
