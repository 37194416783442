.header {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #87CEFA; /* light sky blue */
  padding: 20px;
  color: #0000CD; /* medium blue */
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 10px;
  color: #90EE90; /* light lime green for the logo */
}

.navbar-nav {
  flex-direction: row; /* Flex direction is row to align menu items in a row */
  background-color: transparent;
}

.nav-link {
  color: #0000CD; /* medium blue for the navigation links */
  text-decoration: none;
}

.nav-link:hover {
  color: #32CD32; /* vibrant green for the hover state of navigation links */
}

.header .collapse.open .collapse.show {
  z-index:2;
}
