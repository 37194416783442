.footer {
  background-color: #4a0635;
  color: #f2f2f2;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-text {
  text-align: center;
  margin: 0 10px;
  color: #f2f2f2;
}
